
// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);

// Variables
@import "variables";

// Bootstrap
@import "../css/bootstrap.min.css";

@import "../css/core.min.css";
@import "../css/components.min.css";
@import "../css/colors.min.css";

@import "../css/icons/icomoon/styles.css";

$fa-font-path: "../../../node_modules/font-awesome/fonts" !default;

@import 'node_modules/font-awesome/scss/font-awesome.scss';

@import 'node_modules/ladda/css/ladda.scss';


.fsBody {
  .pageWrapper {
    .fsForm { 
      
      div.fsError {
        border: 1px solid #f7b7a4;
        color: #ce5f6d;
        background-color: #fff4f1;
        line-height: 1.4;
        padding: 15px;
        border-radius: 6px;
      }
      
      .fsSection {
        background-color: #fafafa; 
        
        border-radius: 6px;
        box-shadow: 2px 2px 2px rgba(0,0,0,0.05);
        border: 1px solid rgba(0,0,0,0.05);
        .fsSectionHeader {
          background-color: #fafafa;
          border-radius: 6px;
          padding: 20px;
          .fsSectionHeading {
            font-size:22px;
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
      
      .fsRow {
        margin: 0 !important; 
        .fsRowBody {
          padding: 20px !important;
          margin: 0 !important;
          border-top: 1px solid rgba(0,0,0,0.05);
          hr {
            display: none;
          }
          input[type=number],
          input[type=text],
          input[type=tel],
          input[type=email],
          select,
          textarea {
            border: 1px solid rgba(0,0,0,0.2) !important;
            border-radius: 3px;
            padding-left: 10px;
            &:focus {
              box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
            }
          }
          ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
            opacity: 0.5 !important;
          }
          ::-moz-placeholder { /* Firefox 19+ */
            opacity: 0.5 !important;
          }
          :-ms-input-placeholder { /* IE 10+ */
            opacity: 0.5 !important;
          }
          :-moz-placeholder { /* Firefox 18- */
            opacity: 0.5 !important;
          }
        }
        .fsValidationError {
          background-color: #fff4f1;
          -moz-box-shadow: 0 0 0 calc(5px - 1px) #fff4f1,0 0 0 5px #f7b7a4;
          /* -webkit-box-shadow: 0 0 0 calc(5px - 1px) #fae9e9,0 0 0 5px #ce5f6d; */
          box-shadow: 0 0 0 calc(5px - 1px) #fff4f1,0 0 0 5px #f7b7a4;
        }
        
        .fsLabel {
          font-weight: bold;
          font-size: 14px;
        }
        label.fsOptionLabel {
          padding: 10px 0 10px 30px;
          background: none;
          //border-bottom: 1px solid rgba(0,0,0,0.05);
          margin-bottom: 1px;
          position: relative;
          cursor: pointer;
          line-height: 1.5;
          font-size: 14px;
          input[type=radio] {
            top: 14px;
            left: 8px;
            width: 14px;
            height: 14px;
            position: absolute;
          }
          &:hover {
            //background: #fff;
          }
          &:last-of-type {
            border: none;
          }
        }
        
        
      }
      
      .fsSupporting {
        padding-top: 8px;
        opacity: 0.7;
      }
      
      div.fsProgressBarContainer {
        background: #fafafa;
        color: rgba(0,0,0,0);
        .fsProgressBar {
          background: #bcf3c9;
        } 
        div.fsProgressText {
          display: none;
        }
      }
      
    }
  }
  font-family: "filson-soft",sans-serif;
  input,
  select,
  button {
    font-family: "filson-soft",sans-serif;
  }
  
}
